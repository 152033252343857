import { ReferralUser } from '@fable/types'
import { getReferralName } from 'app/social/helpers'
import { routes } from 'app/social/routes'
import { useTypedSelector } from 'hooks'
import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { getReferralId } from 'utils'

export const useLoadInvite = ({
  redirectOnSignUp = false,
}: { redirectOnSignUp?: boolean } = {}) => {
  const { userSignUp } = useTypedSelector(({ auth }) => auth)
  const navigate = useNavigate()
  const { id } = getReferralId()
  const hasReferralId = !!id

  useEffect(() => {
    if (redirectOnSignUp && userSignUp.data?.isSignedUp) {
      navigate(routes.inviteWelcome)
    }
  }, [navigate, userSignUp.data?.isSignedUp, redirectOnSignUp])

  const { data } = useQuery('referral', () => getReferralName(id as string), {
    enabled: hasReferralId,
    retry: false,
  })

  const referralUser = data?.data as ReferralUser

  return {
    hasReferralId,
    referralUser,
    enabled: hasReferralId && !!referralUser,
  }
}
