import { css, useTheme } from '@fable/theme'
import { Link, useNavigate } from 'react-router-dom'
import { routes } from 'app/marketing/routes'
import { LogoV2 } from 'components/logo_v2'
import { featuresMap } from './featuresMap'
import { useHomeBgColor } from 'components/home_page/lib/hooks'
import { HomeFeaturesKey } from 'components/home_page/lib/types'
import {
  AppreciationPosts,
  HomeFeatures,
  HomeFeaturesContainer,
  HomeHero,
  StarSpinner,
} from 'components/home_page/components'
import { Page } from 'components/Page'
import referralHeadImage from 'assets/images/graphics/referral-credit-illustration.webp'
import { InviteCta } from './InviteCta'
import { InviteUserCard } from 'components/invite_user_card/InviteUserCard'
import { useLoadInvite } from 'hooks'

const InvitePageV2 = () => {
  const navigate = useNavigate()
  const { colors, mediaQueries } = useTheme()
  const {
    bgColor,
    discoverRef,
    discussRef,
    appreciationPostsRef,
    organizeRef,
  } = useHomeBgColor({ defaultColor: colors.uiTeal })

  const { referralUser, hasReferralId } = useLoadInvite({
    redirectOnSignUp: true,
  })

  if (!hasReferralId) navigate('/404')

  const assignRef = (id: HomeFeaturesKey) => {
    switch (id) {
      case 'discover':
        return discoverRef
      case 'discuss':
        return discussRef
      case 'organize':
        return organizeRef
      default:
        return null
    }
  }

  return (
    <Page
      className={css`
        overflow: hidden;
        position: relative;
      `}
      head={{
        title: '🎁📚 Here’s $5 off an ebook on Fable, the social reading app',
        image: referralHeadImage,
      }}
    >
      <Link
        className={css`
          position: absolute;
          top: 14px;
          left: 32px;
          z-index: 1;
          ${mediaQueries.mobileL} {
            top: 10px;
          }
        `}
        to={routes.home}
      >
        <LogoV2 light />
      </Link>
      <HomeHero
        className={css`
          padding-top: 32px;
          ${mediaQueries.mobileL} {
            padding-top: 0;
          }
        `}
        title="The modern app for every reader"
        description="Beautifully designed. Independently owned. Personalized just for you."
        imagesTopId="zdDdaRZ1L56TZuWoacS9c"
        imagesBottomId="Ze19d0fKyQoWH8qsJyyYt"
        button={
          <InviteCta
            className={css`
              margin-top: 10px;
              ${mediaQueries.tablet} {
                margin-top: 12px;
              }
            `}
            negative
          />
        }
      />
      <HomeFeaturesContainer backgroundColor={bgColor}>
        {featuresMap.map((feature, i) => (
          <HomeFeatures key={i} ref={assignRef(feature.id)} {...feature} />
        ))}
      </HomeFeaturesContainer>
      <StarSpinner />
      <AppreciationPosts ref={appreciationPostsRef} />
      {!!referralUser && <InviteUserCard user={referralUser} />}
    </Page>
  )
}

export default InvitePageV2
