import { useFeatureFlags } from 'hooks'
import InvitePage from './InvitePage'
import InvitePageV2 from './v2/InvitePageV2'
import { FlexBox, Loader, LoaderType } from '@fable/components'
import { css } from '@fable/theme'

const InvitePageAB = () => {
  const [enabled, loading] = useFeatureFlags((s) => [
    s.flagEnabled('referralInviteV2'),
    s.loading,
  ])

  if (loading) {
    return (
      <FlexBox
        className={css`
          width: 100%;
          min-height: 100dvh;
        `}
        centerAll
      >
        <Loader type={LoaderType.contained} />
      </FlexBox>
    )
  }

  return enabled ? <InvitePageV2 /> : <InvitePage />
}

export default InvitePageAB
